import texture from '@a/images/texture-desktop.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { px } from './layout/styles/classes';
import { textGradient } from './layout/styles/Typography';

export const Article = styled.article<{ post?: boolean }>`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    max-width: 1264px;
    margin: 0 auto;

    > h2:first-of-type {
        ${textGradient};
        margin: 0 0 24px;
        text-transform: capitalize;
    }

    > .gatsby-image-wrapper:first-of-type {
        border-radius: 8px;
        height: 450px;
        margin-bottom: 32px;
    }

    ${({ post, theme }) =>
        post &&
        css`
            > h2:not(:first-of-type),
            > h3 {
                margin: 29px 0;
                text-transform: capitalize;
                font-weight: 600;
            }

            > h2 {
                ${textGradient};
            }

            > h3 {
                color: ${theme.colors.dark};
            }

            > h4 {
                color: ${theme.colors.dark};
                font-size: 1.375rem;
                font-weight: 600;
            }

            > p {
                :not(:first-of-type, :nth-of-type(2)) {
                    margin: 29px 0;
                }

                :last-of-type {
                    margin-bottom: 0;
                }
            }
        `};

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;

        ::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 1200px;
            background: url(${texture}) no-repeat;
            background-size: 100% auto;
            z-index: -1;
        }

        > h2 {
            font-weight: 700 !important;

            :first-of-type {
                margin: 0 0 48px;
            }
        }

        > .gatsby-image-wrapper:first-of-type {
            margin-bottom: 64px;
        }
    }
`;
