import { Article } from '@/Article';
import { Divider } from '@/Divider';
import { ImageArticle } from '@/ImageArticle';
import { Seo } from '@/layout/Seo';
import { Tag } from '@/Tag';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Wrapper = styled.div`
    position: relative;
    width: fit-content;

    > .gatsby-image-wrapper:last-of-type {
        display: none;
        border: 8px solid white;
        position: absolute;
        bottom: 0;
        right: -64px;

        /* reset image article global styles */
        margin: 0;
        border-radius: 0;
        max-width: max-content;
        height: unset;
    }

    @media (min-width: 768px) {
        > .gatsby-image-wrapper:last-of-type {
            display: block;
        }
    }

    @media (min-width: 1024px) {
        > .gatsby-image-wrapper:last-of-type {
            right: unset;
            left: 250px;
            bottom: -32px;
        }
    }

    @media (min-width: 1280px) {
        > .gatsby-image-wrapper:last-of-type {
            left: 280px;
        }
    }
`;

export default function OurDoctors() {
    return (
        <Fragment>
            <Seo
                title="Our Doctors | Simhairy Dental"
                description="If you are looking for a friendly, compassionate, and experienced dental practice then Simhiary Dental is the place for you. Learn more about our doctors!"
            />
            <Article>
                <Tag text="about" />
                <h2>Our Doctors</h2>
                <StaticImage
                    src="../../../assets/images/about/doctors/hero.jpg"
                    alt="dr luay simhairy with his staff"
                    layout="fullWidth"
                />
                <ImageArticle>
                    <Wrapper>
                        <StaticImage
                            src="../../../assets/images/dr-luay.jpg"
                            alt="dr luay simhairy"
                            width={500}
                            height={500}
                        />

                        <StaticImage
                            src="../../../assets/images/about/doctors/certificate.png"
                            alt="dr luay simhairy aafe certificate"
                            width={271}
                            height={209}
                        />
                    </Wrapper>
                    <div>
                        <h2>Dr. Luay Simhairy, DDS</h2>
                        <p>
                            Dr. Luay Simhairy has a DDS(Doctor of Dental Surgery) from USC, member
                            of OKU (Omicron Kappa Upsilon) and member of AAFE ( American Academy of
                            Facial Esthetic).  He had experience more than 20 years in dental field.
                            He works in different offices providing dental health to El Cajon and
                            Rancho San Diego community. 
                        </p>
                    </div>
                </ImageArticle>
                <Divider />
                <ImageArticle right>
                    <StaticImage
                        src="../../../assets/images/about/doctors/dr-firas.jpg"
                        alt="dr firas najeeb"
                        width={500}
                        height={500}
                        quality={100}
                    />
                    <div>
                        <h2>Dr. Firas Najeeb, DDS</h2>
                        <p>
                            Dr. Firas Najeeb is a Board Certified Orthodontist, He received his DDS
                            degree in 2012 from New York University, College of Dentistry in New
                            York, where he graduated in the top tier in his class. After graduation,
                            Dr. Najeeb moved to Henderson, Nevada to continue his education at
                            Roseman University of health science where he received his Certificate
                            in Orthodontics and Masters in Business Administration. Dr. Najeeb is
                            Passionate about orthodontics , He loves treating children, teens and
                            adults. He provides all orthodontic services to our patients, including
                            traditional & clear braces and Aligners.
                        </p>
                    </div>
                </ImageArticle>
                <Divider />
                <ImageArticle>
                    <StaticImage
                        src="../../../assets/images/about/doctors/dr-frank.jpg"
                        alt="dr frank mac"
                        width={500}
                        height={500}
                        quality={100}
                    />
                    <div>
                        <h2>Dr. Frank Mac, DDS</h2>
                        <p>
                            Dr. Frank Mac is a Board Certified Oral and Maxillofacial Surgeon who
                            has been practicing for over 25 years. When Dr. Mac completed his Doctor
                            of Dental Surgery degree from the University of California, San
                            Francisco, he immediately commissioned as a US Navy Dental Officer. He
                            then went on to specialize in Oral Surgery at Walter Reed Medical Center
                            in Bethesda, Maryland. His time there treating trauma to soldiers,
                            Marines and sailors gave him ample experiences in head and facial
                            reconstruction. Dr. Mac retired from the Navy and now welcomes the
                            opportunity to help manage all your surgical needs from simple dental
                            extractions to oral reconstruction with dental implants and bone
                            grafts. 
                        </p>
                    </div>
                </ImageArticle>
            </Article>
        </Fragment>
    );
}
